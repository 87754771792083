/** @module application */

async function autoScrolling(options) {
    let { AutoScrolling } = await import(/* webpackMode: "lazy" */ './auto-scrolling');

    return AutoScrolling.singleton(options);
}

async function liveEvents(options = {}) {
    let { LiveEvents } = await import(/* webpackMode: "lazy" */ './live-events');

    return LiveEvents.singleton(options);
}

import { ajaxAddToCart } from './ajax-add-to-cart';
import { bugsnag } from './bugsnag';
import { clearAway } from './clear-away';
import { globalState } from './globalState';
import { serviceWorker } from './service-worker';
import { handleTabKey } from './handle-tab-key';

export { autoScrolling, ajaxAddToCart, bugsnag, clearAway, globalState, liveEvents, serviceWorker, handleTabKey };
