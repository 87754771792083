/** @module utils */

import { eventListenerOptions } from '@spinnwerk/polyfills';
import { liveEvents } from '../application';

/**
 * Checks, whether items selected by parameter <code>selector</code> are all in the same visual row. Re-validates with
 * every resize of the browser. Sets classes <code>is-first</code> on each item, that starts a new visual row, and
 * <code>is-last</code> on each item, that is the last item in the visual row.
 *
 * @param {string} selector Selector for the items to check.
 */
export function firstLastClasses(selector) {
    if (typeof selector !== 'string' || selector === '') {
        throw "Parameter 'selector' is missing or of invalid type.";
    }

    function checkItems() {
        const items = document.querySelectorAll(selector);

        let prevTop = null;

        items.forEach((item) => {
            item.classList.remove('is-first');
            item.classList.remove('is-last');

            // first item or entered a new row
            if (prevTop === null || prevTop < item.offsetTop) {
                // add 'is-last' to previous element after entering new row
                if (item.previousElementSibling) {
                    item.previousElementSibling.classList.add('is-last');
                }

                prevTop = item.offsetTop;

                item.classList.add('is-first');
            }
        });

        if (items.length > 0) {
            // add 'is-last' also to last item in the set of elements
            items[items.length - 1].classList.add('is-last');
        }
    }

    checkItems();

    liveEvents().then((LiveEvents) => {
        LiveEvents.addEventListener(window, 'resize', checkItems, eventListenerOptions({ passive: true }), window);
    });
}
