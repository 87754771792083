/** @module application */

function toggleAddToCartLoadingIndicator(added, el) {
    if (added === true) {
        el.classList.toggle('is-loading');
        el.classList.toggle('is-loading--svg-active');
    } else {
        el.classList.toggle('is-loading');
        el.classList.toggle('is-loading--svg-active');
    }
}
/**
 * Enable ajax add to cart functionality for WooCommerce
 *
 * @returns {Promise<void>}
 */
export async function ajaxAddToCart() {
    let ajaxAddToCartLinks = document.querySelectorAll('.ajax_add_to_cart'),
        regularAddToCartSubmitButton = document.querySelectorAll('.simple_add_to_cart'),
        inputQuantity = document.querySelectorAll('input.qty');

    if (ajaxAddToCartLinks.length > 0) {
        // toggle loading indicator for ajax add to cart button (jQuery needed because of woocommerce custom jquery event)
        jQuery(document.body).on('adding_to_cart', () => {
            toggleAddToCartLoadingIndicator(false, document.querySelector('a.ajax_add_to_cart.loading'));
        });

        jQuery(document.body).on('added_to_cart', () => {
            toggleAddToCartLoadingIndicator(true, document.querySelector('a.ajax_add_to_cart.is-loading'));
            inputQuantity.forEach((el) => {
                // reset quantity field
                el.value = 1;
            });
        });

        // hide regular submit button when JS is active
        regularAddToCartSubmitButton.forEach((submit) => {
            submit.style.display = 'none';
        });

        ajaxAddToCartLinks.forEach((el) => {
            el.style.display = 'inline-flex';

            el.addEventListener('click', () => {
                // set data-quantity of 'ajax add to cart' to current value set in the quantity field
                el.dataset.quantity = el.previousElementSibling.querySelector('input.qty').value;
            });
        });
    }
}
